import { Locale } from 'date-fns';
import { enGB, enUS, es, ru } from 'date-fns/locale';
import { MapboxOptions } from 'mapbox-gl';
import { FundColumnsMapping, BulkFundColumn, SelectOption } from './models';
import {
  formatNumberToLocaleString,
  formatNumberToPercentage,
  formatBooleanToString,
} from './utils';

type DateFnsLocaleMapping = {
  [key: string]: Locale;
};

export const dateFnsLocaleMapping: DateFnsLocaleMapping = {
  'en-GB': enGB,
  'en-US': enUS,
  es,
  ru,
};

export const assetClassRoutesMap: { [key: string]: string } = {
  equity: '/equities',
  corporate_debt: '/corporate-debt',
  sovereign_debt: '/sovereign-debt',
  corporate: '/companies',
  private_equity: '/private-equities',
  real_asset: '/real-assets',
  corporate_mod: '/corporate-mod',
};

export const getFundColumns: (t: any) => FundColumnsMapping = (t: any) => ({
  listed: {
    sortedBy: 'isin',
    columns: [
      {
        key: 'isin',
        width: 175,
        noFlex: true,
        editable: true,
        placeholder: t('holdingPlaceholder', { label: t('isin') }),
      },
      {
        key: 'name',
        width: 250,
        editable: true,
        placeholder: t('holdingPlaceholder', { label: t('name').toLowerCase() }),
      },
      {
        key: 'exposure',
        width: 200,
        type: 'number',
        format: 'number',
        editable: true,
        formatter: formatNumberToLocaleString,
        placeholder: t('Enter exposure in USD'),
      },
      {
        key: 'coverage',
        width: 120,
        excludeFromCSV: true,
        type: 'boolean',
        formatter: (cellData: any) =>
          cellData ? t('Yes', { ns: 'common' }) : t('No', { ns: 'common' }),
      },
      {
        key: 'asset_class',
        width: 100,
        excludeFromCSV: true,
        formatter: (val: any) => (val !== null ? t(val) : t('unknown')),
      },
    ],
  },
  corporate_mod: {
    sortedBy: 'name',
    columns: [
      {
        key: 'mod_id',
        width: 100,
        showOnDelete: true,
      },
      {
        key: 'name',
        showOnDelete: true,
      },
      {
        key: 'sector_classificationsystem',
        width: 150,
        shortName: 'Sector classification',
        hidden: true,
      },
      {
        key: 'sector',
        width: 150,
        excludeFromCSV: true,
      },
      {
        key: 'sector_code',
        width: 120,
        hidden: true,
      },
      {
        key: 'country',
        width: 100,
        shortName: 'HQ',
        showOnDelete: true,
      },
      {
        key: 'revenue',
        width: 120,
        formatter: formatNumberToLocaleString,
      },
      {
        key: 'company_value',
        width: 160,
        formatter: formatNumberToLocaleString,
      },
      {
        key: 'total_liabilities',
        width: 155,
        formatter: formatNumberToLocaleString,
      },
      {
        key: 'exposure',
        width: 120,
        formatter: formatNumberToLocaleString,
      },
      {
        key: 'corporation_tax_rate',
        width: 200,
        hidden: true,
        formatter: formatNumberToPercentage,
      },
      { key: 'net_income', width: 135, hidden: true },
      { key: 'property_plant_equipment_value', width: 300, hidden: true },
      { key: 'investment_properties_value', width: 250, hidden: true },
      { key: 'scope1_emissions', width: 175, hidden: true },
      { key: 'scope2_emissions', width: 175, hidden: true },
      { key: 'scope3_emissions', width: 175, hidden: true },
      { key: 'total_assets', hidden: true },
      { key: 'working_capital', hidden: true },
      { key: 'credit_rating_classification', hidden: true },
      { key: 'credit_rating', hidden: true },
      { key: 'retained_earnings', hidden: true },
      { key: 'ebit', hidden: true },
      { key: 'initial_pd', hidden: true },
      { key: 'initial_lgd', hidden: true },
    ],
  },
  sectors: {
    sortedBy: 'sector',
    columns: [
      { key: 'id' },
      { key: 'sector_classificationsystem', hidden: true },
      { key: 'sector' },
      { key: 'percent_revenue', formatter: formatNumberToPercentage },
      { key: 'percent_assets', formatter: formatNumberToPercentage },
    ],
  },
  regions: {
    sortedBy: 'country',
    columns: [
      { key: 'id' },
      { key: 'country' },
      { key: 'percent_revenue', formatter: formatNumberToPercentage },
      { key: 'percent_assets', formatter: formatNumberToPercentage },
    ],
  },
  real_estate: {
    sortedBy: 'country',
    columns: [
      { key: 'country', control: 'select', defaultValue: 'Select country', editable: true },
      {
        key: 'building_type',
        control: 'select',
        options: ['commercial', 'residential'],
        defaultValue: 'Select building type',
        editable: true,
        formatter: (val: any) => t(val),
      },
      { key: 'floor_area_m2', type: 'number', editable: true },
      {
        key: 'exposure',
        type: 'number',
        format: 'number',
        editable: true,
        formatter: formatNumberToLocaleString,
      },
    ],
  },
  private_equity: {
    sortedBy: 'country',
    columns: [
      {
        key: 'country',
        width: 350,
        control: 'select',
        defaultValue: 'Select country',
        editable: true,
      },
      {
        key: 'sector_classificationsystem',
        hidden: true,
        formatter: (val: any) => val.toUpperCase(),
      },
      {
        key: 'sector_code',
        hidden: true,
      },
      {
        key: 'sector',
        width: 300,
        excludeFromImportModule: true,
        control: 'select',
        defaultValue: 'Select sector',
        editable: true,
      },
      {
        key: 'exposure',
        width: 200,
        type: 'number',
        format: 'number',
        editable: true,
        formatter: formatNumberToLocaleString,
      },
    ],
  },
  entities: {
    sortedBy: 'name',
    columns: [
      { key: 'pat_id', width: 150 },
      { key: 'name', width: 250 },
      { key: 'isin', width: 175 },
      { key: 'country', WIDTH: 180, control: 'select' },
      {
        key: 'exposure',
        width: 150,
        type: 'number',
        format: 'number',
        formatter: formatNumberToLocaleString,
      },
      {
        key: 'evic',
        width: 150,
        type: 'number',
        format: 'number',
        formatter: formatNumberToLocaleString,
      },
      { key: 'activities_count', width: 150, type: 'number' },
      { key: 'sector_classificationsystem', width: 180 },
      { key: 'sector_code', width: 130 },
    ],
  },
  activities: {
    sortedBy: 'entity_id',
    columns: [
      { key: 'entity_id', width: 120 },
      {
        key: 'scope1_emissions',
        width: 160,
        type: 'number',
        format: 'number',
        formatter: formatNumberToLocaleString,
      },
      { key: 'scope1_emissions_source', width: 180 },
      { key: 'scope1_emissions_type', width: 200 },
      { key: 'scope1_emissions_year', width: 150 },
      {
        key: 'scope1_emissions_force',
        formatter: formatBooleanToString,
        width: 150,
      },
      {
        key: 'scope2_emissions',
        width: 180,
        type: 'number',
        format: 'number',
        formatter: formatNumberToLocaleString,
      },
      { key: 'scope2_emissions_source', width: 180 },
      { key: 'scope2_emissions_type', width: 180 },
      { key: 'scope2_emissions_year', width: 150 },
      { key: 'scope2_emissions_force', formatter: formatBooleanToString, width: 150 },
      {
        key: 'scope3_emissions',
        width: 180,
        type: 'number',
        format: 'number',
        formatter: formatNumberToLocaleString,
      },
      { key: 'scope3_emissions_source', width: 180 },
      { key: 'scope3_emissions_type', width: 180 },
      { key: 'scope3_emissions_year', width: 150 },
      { key: 'scope3_emissions_force', formatter: formatBooleanToString, width: 150 },
      { key: 'production_year', width: 180 },
      { key: 'production_sector', width: 180 },
      { key: 'production_tech', width: 180 },
      { key: 'production_unit', width: 180 },
      {
        key: 'production',
        width: 180,
        type: 'number',
        format: 'number',
        formatter: formatNumberToLocaleString,
      },
      {
        key: 'revenue',
        width: 150,
        type: 'number',
        format: 'number',
        formatter: formatNumberToLocaleString,
      },
      { key: 'revenue_year', width: 150 },
      { key: 'revenue_currency', width: 180 },
      { key: 'sector_classificationsystem', width: 200 },
      { key: 'sector_code', width: 150 },
      { key: 'country', width: 150 },
    ],
  },
  real_asset: {
    sortedBy: 'country',
    columns: [
      { key: 'name', width: 250 },
      {
        key: 'building_type',
        control: 'select',
        options: ['commercial', 'residential', 'industrial'],
        defaultValue: 'Select building type',
        formatter: (val: any) => t(val),
      },
      {
        key: 'country',
        control: 'select',
        defaultValue: 'Select country',
      },
      { key: 'latitude', width: 150 },
      { key: 'longitude', width: 150 },
      { key: 'address', width: 250 },
      { key: 'entity_value', width: 150, type: 'number', formatter: formatNumberToLocaleString },
      {
        key: 'exposure',
        width: 150,
        type: 'number',
        formatter: formatNumberToLocaleString,
        hidden: true,
        excludeFromCSV: true,
      },
      { key: 'floor_area_m2', width: 150, type: 'number', formatter: formatNumberToLocaleString },
      {
        key: 'natural_gas_consumption',
        width: 150,
        type: 'number',
        formatter: formatNumberToLocaleString,
      },
      {
        key: 'electricity_consumption',
        width: 200,
        type: 'number',
        formatter: formatNumberToLocaleString,
      },
      {
        key: 'water_consumption',
        width: 200,
        type: 'number',
        formatter: formatNumberToLocaleString,
      },
      { key: 'energy_efficiency_rating', width: 200 },
    ],
  },
});

export const companyLevelResultTypesMapping: { [key: string]: string } = {
  id: 'string',
  name: 'string',
  country: 'string',
  exposure: 'number',
  weight: 'number',
  weighted_value: 'number',
  asset_class: 'string',
  emissions_type: 'string',
  pcaf: 'number',
};

export const defaultMapOptions: Partial<MapboxOptions> = {
  transformRequest: (url: string) => {
    return { url };
  },
  //   API_ROOT && url.startsWith(API_ROOT)
  //     ? {
  //       url
  //     }
  //     : {
  //       url
  //     },
  // zoom: 4,
  // center: [72.8777, 19.076],
  // dragRotate: false,
  // touchZoomRotate: false
};

export const fundColorsPreset = [
  '#d43a3a',
  '#d46a3a',
  '#d48a3a',
  '#d4ad3a',
  '#d4c53a',
  '#aad43a',
  '#69d43a',
  '#3ad4a3',
  '#3abad4',
  '#3a91d4',
  '#3a70d4',
  '#3a3fd4',
  '#5b3ad4',
  '#853ad4',
  '#bf3ad4',
  '#d43abf',
  '#d43a75',
  '#000000',
];

export const VirtualSelectRowsLimit = 1000;
export const InfiniteTableRowsLimit = 1000;
export const FundsLimit = 200;

export const SelectedScenariosLimit = 3;
export const VirtualRowHeight = 26;
export const InfiniteTableRowHeight = 48;

export const YearsOptions: SelectOption[] = [
  { name: '2021', value: '2021' },
  { name: '2022', value: '2022' },
  { name: '2023', value: '2023' },
  { name: '2024', value: '2024' },
  { name: '2025', value: '2025' },
  { name: '2026', value: '2026' },
  { name: '2027', value: '2027' },
  { name: '2028', value: '2028' },
  { name: '2029', value: '2029' },
  { name: '2030', value: '2030' },
  { name: '2035', value: '2035' },
  { name: '2040', value: '2040' },
  { name: '2045', value: '2045' },
  { name: '2050', value: '2050' },
];

export const bulkFundColumns: BulkFundColumn[] = [
  {
    key: 'fund_name',
    required: true,
  },
  {
    key: 'fund_type',
    required: true,
  },
  {
    key: 'benchmark_name',
  },
  {
    key: 'asset_class',
    required: true,
  },
  {
    key: 'exposure',
    required: true,
  },
  {
    key: 'isin',
  },
  {
    key: 'name',
  },
  {
    key: 'country',
  },
  {
    key: 'sector_classificationsystem',
  },
  {
    key: 'sector_code',
  },
  {
    key: 'building_type',
  },
  {
    key: 'floor_area_m2',
  },
];

export const corporateCsvColumns: { [key: string]: string[] } = {
  entities: [
    'id',
    'isin',
    'name',
    'exposure',
    'evic',
    'country',
    'sector_classificationsystem',
    'sector_code',
  ],
  activities: [
    'entity_id',
    'scope1_emissions_source',
    'scope1_emissions_type',
    'scope1_emissions_year',
    'scope1_emissions_force',
    'scope2_emissions_source',
    'scope2_emissions_type',
    'scope2_emissions_year',
    'scope2_emissions_force',
    'scope3_emissions_source',
    'scope3_emissions_type',
    'scope3_emissions_year',
    'scope3_emissions_force',
    'scope1_emissions',
    'scope2_emissions',
    'scope3_emissions',
    'production_year',
    'production_sector',
    'production_tech',
    'production_unit',
    'production',
    'revenue',
    'revenue_year',
    'revenue_currency',
    'sector_classificationsystem',
    'sector_code',
    'country',
  ],
};

export const baseURL = `${process.env.REACT_APP_API_ROOT}${
  process.env.REACT_APP_API_VERSION ? '/v' + process.env.REACT_APP_API_VERSION + '/' : ''
}`;

export const centralAPI = `${process.env.REACT_APP_PLANETVIEW_CENTRAL_API}${
  process.env.REACT_APP_CENTRAL_API_VERSION
    ? '/v' + process.env.REACT_APP_CENTRAL_API_VERSION + '/'
    : ''
}`;

export const HazardMetricMinLimit = 0.04;
