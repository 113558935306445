import { cloneDeep } from 'lodash';
import { SortType } from './models';
import FileDownload from 'js-file-download';
import Decimal from 'decimal.js';

export const generateDarkColorHex = () => {
  let color = '#';
  for (let i = 0; i < 3; i++) {
    color += ('0' + Math.floor((Math.random() * Math.pow(16, 2)) / 2).toString(16)).slice(-2);
  }
  return color;
};

export const parseChannel = (channel: string) => {
  switch (channel) {
    case 'T':
      return {
        physical: false,
        transition: true,
      };
    case 'P':
      return {
        physical: true,
        transition: false,
      };
    default:
      return {
        physical: true,
        transition: true,
      };
  }
};
export const encodeChannel = (physical: boolean, transition: boolean) => {
  if (physical && transition) {
    return 'C';
  } else if (transition) {
    return 'T';
  } else if (physical) {
    return 'P';
  }
  return 'C';
};

export const generateButton = (svg: SVGSVGElement, title: string, onClick: () => void) => {
  const button = document.createElement('a');
  button.appendChild(svg);
  button.className = 'modebar-btn';
  button.setAttribute('rel', 'tooltip');
  button.setAttribute('data-title', title);
  button.setAttribute('data-toggle', 'false');
  button.setAttribute('data-gravity', 'n');
  button.onclick = onClick;
  return button;
};

export const generateCSVButton = (onClick: () => void, title: string) => {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttribute('viewBox', '0 0 12 10');
  svg.setAttribute('height', '1em');
  svg.setAttribute('width', '1em');
  svg.className.baseVal = 'icon';
  svg.innerHTML = `<path d="M5.91552 6.99303C5.92554 7.00583 5.93835 7.01619 5.95297 7.02331C5.96759 7.03043 5.98363 7.03413 5.9999 7.03413C6.01616 7.03413 6.0322 7.03043 6.04682 7.02331C6.06144 7.01619 6.07425 7.00583 6.08427 6.99303L7.58427 5.09526C7.63918 5.02561 7.58963 4.92249 7.4999 4.92249H6.50748V0.390346C6.50748 0.331417 6.45927 0.283203 6.40034 0.283203H5.59677C5.53784 0.283203 5.48963 0.331417 5.48963 0.390346V4.92115H4.4999C4.41016 4.92115 4.36061 5.02428 4.41552 5.09392L5.91552 6.99303ZM10.9017 6.52428H10.0981C10.0392 6.52428 9.99097 6.57249 9.99097 6.63142V8.69392H2.00882V6.63142C2.00882 6.57249 1.96061 6.52428 1.90168 6.52428H1.09811C1.03918 6.52428 0.990967 6.57249 0.990967 6.63142V9.2832C0.990967 9.52026 1.18248 9.71178 1.41954 9.71178H10.5803C10.8173 9.71178 11.0088 9.52026 11.0088 9.2832V6.63142C11.0088 6.57249 10.9606 6.52428 10.9017 6.52428Z"/>
  `;
  const button = generateButton(svg, title, onClick);
  return button;
};

export const generatePNGButton = (onClick: () => void, title: string) => {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttribute('viewBox', '0 0 12 11');
  svg.setAttribute('height', '1em');
  svg.setAttribute('width', '1em');
  svg.className.baseVal = 'icon';
  svg.innerHTML = `<path d="M10.7144 2.4668H8.89293L8.459 1.25073C8.42902 1.16751 8.37408 1.09559 8.30169 1.04477C8.2293 0.993949 8.14298 0.966719 8.05453 0.966797H3.94561C3.7648 0.966797 3.60275 1.08064 3.54248 1.25073L3.10721 2.4668H1.28578C0.69382 2.4668 0.214355 2.94626 0.214355 3.53823V9.64537C0.214355 10.2373 0.69382 10.7168 1.28578 10.7168H10.7144C11.3063 10.7168 11.7858 10.2373 11.7858 9.64537V3.53823C11.7858 2.94626 11.3063 2.4668 10.7144 2.4668ZM10.8215 9.64537C10.8215 9.7043 10.7733 9.75251 10.7144 9.75251H1.28578C1.22686 9.75251 1.17864 9.7043 1.17864 9.64537V3.53823C1.17864 3.4793 1.22686 3.43108 1.28578 3.43108H3.78623L4.01525 2.7909L4.32195 1.93108H7.67686L7.98355 2.7909L8.21257 3.43108H10.7144C10.7733 3.43108 10.8215 3.4793 10.8215 3.53823V9.64537ZM6.00007 4.28823C4.81614 4.28823 3.85721 5.24715 3.85721 6.43108C3.85721 7.61501 4.81614 8.57394 6.00007 8.57394C7.184 8.57394 8.14293 7.61501 8.14293 6.43108C8.14293 5.24715 7.184 4.28823 6.00007 4.28823ZM6.00007 7.7168C5.29025 7.7168 4.71436 7.1409 4.71436 6.43108C4.71436 5.72126 5.29025 5.14537 6.00007 5.14537C6.70989 5.14537 7.28578 5.72126 7.28578 6.43108C7.28578 7.1409 6.70989 7.7168 6.00007 7.7168Z"/>`;
  const button = generateButton(svg, title, onClick);
  return button;
};

export const generateInfoButton = (onClick: () => void, title: string) => {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttribute('viewBox', '0 0 24 24');
  svg.setAttribute('height', '1em');
  svg.setAttribute('width', '1em');
  svg.className.baseVal = 'icon';
  svg.innerHTML = `<path class="info-path" d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm0,5.75A1.25,1.25,0,1,1,10.75,7,1.25,1.25,0,0,1,12,5.75ZM14,18H10V17c.48-.18,1-.2,1-.73V11.8c0-.54-.52-.62-1-.8V10h3v6.27c0,.53.52.55,1,.73Z" />`;
  const button = generateButton(svg, title, onClick);
  return button;
};

export const compareWithoutAccents = (
  origin: string | string[],
  searchTerm: string,
  exactMatchSearchTerms: string[] = [],
) => {
  if (exactMatchSearchTerms.some((term) => term === searchTerm)) {
    return true;
  }
  const normalizedSearchTerm = searchTerm
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  if (Array.isArray(origin)) {
    const normalizedOrigin = origin.map((searchTerm) =>
      searchTerm
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase(),
    );
    return normalizedOrigin.some((searchTerm) => searchTerm.includes(normalizedSearchTerm));
  }
  const normalizedOrigin = origin
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  return normalizedOrigin.includes(normalizedSearchTerm);
};

export const formatFileSize = (bytes: number | null): string => {
  if (bytes === null) {
    return '';
  }
  const i: number = Math.floor(Math.log(bytes) / Math.log(1024));
  // @ts-ignore
  return `${(bytes / Math.pow(1024, i)).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
};

type SortDataProps<T> = {
  data: T[];
  sorting: {
    key: keyof T;
    dir: SortType;
  };
  type: string;
};

export const sortData = ({ data, sorting, type }: SortDataProps<any>) => {
  const sorted = cloneDeep(data);
  const { dir, key } = sorting;
  if (type === 'string') {
    return sorted.sort((a, b) => {
      const aIsString = typeof a[key] === 'string';
      const bIsString = typeof b[key] === 'string';
      if (!aIsString || !bIsString) {
        if (!aIsString && bIsString) {
          return dir === 'asc' ? 1 : -1;
        }
        if (aIsString && !bIsString) {
          return dir === 'asc' ? -1 : 1;
        }
        if (!aIsString && !bIsString) {
          return 0;
        }
      }
      if (dir === 'asc') {
        // @ts-ignore
        return a[key].localeCompare(b[key]);
      } else {
        // @ts-ignore
        return b[key].localeCompare(a[key]);
      }
    });
  }
  if (type === 'date') {
    return sorted.sort((a, b) => {
      if (dir === 'asc') {
        // @ts-ignore
        return new Date(a[key]).getTime() - new Date(b[key]).getTime();
      } else {
        // @ts-ignore
        return new Date(b[key]).getTime() - new Date(a[key]).getTime();
      }
    });
  }
  if (type === 'boolean') {
    return sorted.sort((a, b) => {
      const aNumber = Number(a[sorting.key]);
      const bNumber = Number(b[sorting.key]);
      return sorting.dir === 'asc' ? aNumber - bNumber : bNumber - aNumber;
    });
  }
  return sorted.sort((a, b) => {
    const aIsNaN = isNaN(a[key]) || a[key] === '';
    const bIsNaN = isNaN(b[key]) || b[key] === '';
    if (aIsNaN || bIsNaN) {
      if (aIsNaN && !bIsNaN) {
        return dir === 'asc' ? 1 : -1;
      }
      if (!aIsNaN && bIsNaN) {
        return dir === 'asc' ? -1 : 1;
      }
      if (aIsNaN && bIsNaN) {
        return 0;
      }
    }
    if (dir === 'asc') {
      // @ts-ignore
      return a[key] - b[key];
    } else {
      // @ts-ignore
      return b[key] - a[key];
    }
  });
};

// For quantities with a wide range of possible values, where using a decimal for large values
// would be silly but using one decimal place for small values is preferable to fully rounding
export const formatTwoDecimalBelowTen = (x: number) =>
  x.toLocaleString('en-GB', {
    minimumFractionDigits: x < 10 ? 2 : 1,
    maximumFractionDigits: x < 10 ? 2 : 1,
  });

export const formatDecimalDigits = (x: number, n: number) =>
  x.toLocaleString('en-GB', { minimumFractionDigits: 0, maximumFractionDigits: n });

export const toFixedNumber = (num: number, digits: number) => {
  const pow = Math.pow(10, digits);
  return Math.round(num * pow) / pow;
};

export const formatNumberToLocaleString = (val: any) => {
  if (typeof val === 'string' || val === '') {
    return val;
  }

  return (Math.round(val * 100) / 100).toLocaleString();
};

export const formatBooleanToString = (value: boolean) => value?.toString().toUpperCase() || '';

export const areStringArraysEqual = (
  a: string[],
  b: string[],
  orderSensitive?: boolean,
  caseSensitive?: boolean,
) => {
  if (a.length === b.length) {
    return a.every((el, index) => {
      if (orderSensitive) {
        return caseSensitive ? el === b[index] : el.toLowerCase() === b[index].toLowerCase();
      } else {
        return caseSensitive
          ? b.includes(el)
          : b.map((i) => i.toLowerCase()).includes(el.toLowerCase());
      }
    });
  }
  return false;
};

export const getBorderedErrorStyle = (classes: any, errorType: string) => {
  switch (errorType) {
    case 'error': {
      return classes.borderedError;
    }
    case 'warning': {
      return classes.borderedWarning;
    }
    default:
      return '';
  }
};

export const disabledFormSubmit = (form, assetType: string) => {
  const { name, ...data } = form;
  const customizedForm = assetType === 'listed' ? data : form;
  return Object.keys(customizedForm).some((key) => form[key].value === '');
};

export const downloadImage = (base64string: string, filename: string) => {
  if (!base64string) {
    return undefined;
  }
  const arr = base64string.split(',');
  const mime = arr[0]?.match(/:(.*?);/)?.[1] || '';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const uint8Array = new Uint8Array(n);
  while (n--) {
    uint8Array[n] = bstr.charCodeAt(n);
  }
  const file = new File([uint8Array], filename, { type: mime });
  FileDownload(file, filename);
};

export const numberWithCommas = (num: number) =>
  num.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatNumberToPercentage = (value) => {
  if (value && typeof value === 'number') {
    return new Decimal(value).mul(100).toFixed(2);
  }

  return value;
};

export const addOrRemoveFromArray = (array, item) => {
  // TODO Change in all files when is duplicated logic
  const clonedArr = [...array];
  const index = clonedArr.findIndex((i) => i === item);

  if (index >= 0) {
    clonedArr.splice(index, 1);
  } else {
    clonedArr.push(item);
  }

  return clonedArr;
};

export const getAssetTypeOptions = (assetTypes: string[], t: any) => {
  let arr = [] as string[];
  if (assetTypes.includes('listed')) {
    arr = assetTypes.filter((item) => item !== 'listed');
    arr = ['equity', 'corporate_debt', 'sovereign_debt', ...arr];
  }

  return () =>
    Promise.resolve(
      arr.map((asset) => ({ name: t(`assetClasses:${asset}` as string), id: asset })),
    );
};
