import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Model, Settings } from '@models';
import { getSettings, getModels, patchModel } from '@api';
import useGetToken from '@hooks/useGetToken';
import FullPageLoading from '@components/FullPageLoading';
import ErrorScreen from '@components/ErrorScreen';

type ContextType = {
  settings: Settings;
  availableModelVersions: Model[];
  setSettings: (val: Settings) => void;
  modelChangedToLatest: boolean;
  setModelChangedToLatest: (val: boolean) => void;
};

const settingsObj = {
  model_version: '',
  newest_available_model_version: '',
  default_isin_for_equity_page: '',
  default_isin_for_corporate_debt_page: '',
  default_isin_for_sovereign_debt_page: '',
  default_iso3c_for_private_equity_page: '',
  default_sector_for_private_equity_page: 0,
  is_limited_functionality_demo: false,
  display_language: '',
  disclaimer_accepted: true,
  scenarios: [],
  sector_classification: '',
  filters: {},
};

const SettingsContext = createContext<ContextType>({
  settings: settingsObj,
  availableModelVersions: [],
  setSettings: () => undefined,
  modelChangedToLatest: false,
  setModelChangedToLatest: () => undefined,
});

type ProviderProps = {
  children: JSX.Element;
};

export const SettingsProvider = ({ children }: ProviderProps) => {
  const { getToken } = useGetToken();
  const location = useLocation();

  const [settings, setSettings] = useState<Settings>(settingsObj);
  const [modelChangedToLatest, setModelChangedToLatest] = useState<boolean>(false);
  const [availableModelVersions, setAvailableModelVersions] = useState([]);
  const [error, setError] = useState({
    show: false,
    text: '',
  });

  const handleUpdateModel = useCallback(
    async (model) => {
      const token = await getToken();
      patchModel(token, model.version, model.default_scenarios);
    },
    [getToken],
  );

  const setNewModelUpdates = useCallback(
    (selectedVersion: string, newModelVersion: string) => {
      const updateAvailable = newModelVersion && selectedVersion !== newModelVersion;
      if (location?.state?.appState?.from === '/login' && updateAvailable) {
        localStorage.setItem('newModelVersion', newModelVersion);
      }
    },
    [location?.state?.appState?.from],
  );

  useEffect(() => {
    (async () => {
      const token = await getToken();
      Promise.all([getSettings(token), getModels(token)])
        .then(([data, models]) => {
          let currentModel = models.find((model) => model.version === data.model_version);
          if (!currentModel) {
            // Model version in settings isn't available. Try to set `newset_available_model_version` as a current model.
            setModelChangedToLatest(true);
            currentModel = models.find(
              (model) => model.version === data.newest_available_model_version,
            );
            if (!currentModel) {
              // if previous step didn't work - just find the newest available model and set as current.
              currentModel = [...models].sort(
                (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
              )[0];
            }
            handleUpdateModel(currentModel);
          }
          setAvailableModelVersions(models as any);
          setSettings({
            ...data,
            model_version: currentModel?.version,
            sector_classification: currentModel?.sector_classification || '',
          });
          setNewModelUpdates(data.model_version, data.newest_available_model_version);
        })
        .catch((err) => {
          setError({ show: true, text: err?.data?.detail });
        });
    })();
  }, [getToken, handleUpdateModel, setNewModelUpdates]);

  return (
    <SettingsContext.Provider
      value={{
        settings,
        setSettings,
        modelChangedToLatest,
        setModelChangedToLatest,
        availableModelVersions,
      }}
    >
      {settings.model_version === '' && !error.show ? (
        <FullPageLoading />
      ) : (
        <>{error.show ? <ErrorScreen message={error.text} /> : children}</>
      )}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);
