import React, { createContext, useContext, useState, useEffect } from 'react';
import { getAppConfig } from '@api';
import useGetToken from '@hooks/useGetToken';
import FullPageLoading from '@components/FullPageLoading';
import ErrorScreen from '@components/ErrorScreen';
import { useTranslation } from 'react-i18next';
import { SecurityRoute, AnalysisMode, PortfolioConfig, AppDocument } from '@models';

type ContextType = {
  securityRoutes: SecurityRoute[];
  customAssetRoutes: SecurityRoute[];
  defaultAnalysisMode: string;
  analysisModes: AnalysisMode[];
  portfolioConfig: PortfolioConfig;
  extraRoutes: string[];
  appDocuments: AppDocument[];
  locationExplorerModes?: AnalysisMode[];
};

const AppConfigContext = createContext<ContextType>({
  securityRoutes: [],
  customAssetRoutes: [],
  defaultAnalysisMode: '',
  analysisModes: [],
  portfolioConfig: {
    fundTypes: [],
    columns: [],
    assetTypes: [],
    enableMultiFundUpload: false,
  },
  extraRoutes: [],
  appDocuments: [],
  locationExplorerModes: [],
});

type ProviderProps = {
  children: JSX.Element;
};

export const AppConfigProvider = ({ children }: ProviderProps) => {
  const { getToken } = useGetToken();
  const { i18n } = useTranslation();
  const [
    {
      securityRoutes,
      customAssetRoutes,
      defaultAnalysisMode,
      analysisModes,
      portfolioConfig,
      extraRoutes,
      appDocuments,
      locationExplorerModes,
    },
    setAppConfig,
  ] = useState<ContextType>({
    securityRoutes: [],
    customAssetRoutes: [],
    defaultAnalysisMode: '',
    analysisModes: [],
    portfolioConfig: {
      fundTypes: [],
      columns: [],
      assetTypes: [],
      enableMultiFundUpload: false,
    },
    extraRoutes: [],
    appDocuments: [],
    locationExplorerModes: [],
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState({
    show: false,
    text: '',
  });
  useEffect(() => {
    (async () => {
      const token = await getToken();
      getAppConfig(token)
        .then((data) => {
          setAppConfig(data);
        })
        .catch((err) => {
          setError({ show: true, text: err?.data?.detail });
        })
        .finally(() => setLoading(false));
    })();
  }, [getToken, i18n]);
  return (
    <AppConfigContext.Provider
      value={{
        securityRoutes,
        customAssetRoutes,
        defaultAnalysisMode,
        analysisModes,
        portfolioConfig,
        extraRoutes,
        appDocuments,
        locationExplorerModes,
      }}
    >
      {loading ? (
        <FullPageLoading />
      ) : (
        <>
          {children}
          {error.show && <ErrorScreen message={error.text} />}
        </>
      )}
    </AppConfigContext.Provider>
  );
};

export const useAppConfig = () => useContext(AppConfigContext);
