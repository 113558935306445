import { createContext, useContext, useState, useEffect } from 'react';
import useGetToken from '@hooks/useGetToken';
import { getUserInfo } from '@api';
import { UserInfo } from '@models';

type ContextType = {
  userInfo: UserInfo;
  expandedSidebar: boolean;
  setExpandedSidebar: (value: boolean) => void;
  error: string;
  loading: boolean;
};

const UserInfoContext = createContext<ContextType>({
  userInfo: {
    name: '',
    organisation: '',
    picture_url: '',
  },
  expandedSidebar: false,
  setExpandedSidebar: () => undefined,
  error: '',
  loading: false,
});

type ProviderProps = {
  children: JSX.Element;
};

export const UserInfoProvider = ({ children }: ProviderProps) => {
  const { getToken } = useGetToken();
  const [userInfo, setUserInfo] = useState<UserInfo>({
    name: '',
    organisation: '',
    picture_url: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [expandedSidebar, setExpandedSidebar] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const token = await getToken();
      getUserInfo(token)
        .then((res) => {
          setLoading(true);
          setUserInfo(res);
        })
        .catch((err) => {
          setError(err?.data?.error_message);
        })
        .finally(() => setLoading(false));
    })();
  }, [getToken]);

  return (
    <UserInfoContext.Provider
      value={{ userInfo, loading, error, setExpandedSidebar, expandedSidebar }}
    >
      {children}
    </UserInfoContext.Provider>
  );
};

export const useUserInfo = () => useContext(UserInfoContext);
