/* eslint-disable no-unused-vars */
import { createTheme, Theme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface PaletteColor {
    black: string;
    blue: string;
    green: string;
    grey: string;
    lightGrey: string;
    veryLightGrey?: string;
    lightRed: string;
    darkRed: string;
    lightBlue?: string;
    borderGrey?: string;
  }
  interface SimplePaletteColorOptions {
    black?: string;
    blue?: string;
    green?: string;
    grey?: string;
    lightGrey?: string;
    veryLightGrey?: string;
    lightRed?: string;
    darkRed?: string;
    lightBlue?: string;
    borderGrey?: string;
  }
}

export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#2251FF',
      light: '#EEF2FA',
      dark: '#051C2C',
      black: '#191A1C',
      blue: '#2E58ED',
      borderGrey: '#E6E6E6',
    },
    secondary: {
      main: '#d7006d',
      light: '#00A9F4',
      blue: '#384764',
      green: '#E2FFE4',
      grey: '#E7E7E7',
      lightGrey: '#E4E6E9',
      veryLightGrey: '#F7F9FB',
      lightBlue: '#6587FF',
    },
    error: {
      main: '#D51F31',
      dark: '#B53B3B',
      light: '#E14545',
      lightRed: '#FFE9E9',
      darkRed: '#DF2C2C',
    },
    grey: {
      100: '#c3c9d0',
      200: '#516375',
      300: '#FBFCFF',
      400: '#C9CCD6',
      500: '#919399',
      600: '#ffffff1a',
      700: '#B3B4B8',
      800: '#F3F3F3',
      900: '#F9F9F9',
    },
    success: {
      main: '#007F26',
      dark: '#37BD3D',
    },
    warning: {
      main: '#F39201',
      light: '#FFECCF',
      dark: '#DF6B00',
    },
  },
  typography: {
    fontFamily: 'McKinseySans, sans-serif',
    h5: {
      fontSize: 20,
    },
    h6: {
      fontSize: 16,
    },
    button: {
      textTransform: 'none',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 1920,
    },
  },
});

theme.components = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        fontSize: '0.875rem',
        lineHeight: 1.43,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        lineHeight: 1,
      },
    },
  },
  MuiTable: {
    styleOverrides: {
      root: {
        width: '100%',
        borderCollapse: 'separate',
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        '& .MuiTableCell-body': {
          '&:first-of-type': {
            color: theme.palette.primary.black,
            borderRadius: '6px 0 0 6px',
          },
          '&:last-child': {
            borderRadius: '0 6px 6px 0',
          },
        },
      },
      head: {
        '&:nth-of-type(odd)': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        border: 'none',
        padding: `${theme.spacing(1.5)} ${theme.spacing(1)}`,
        color: '#516375',
        height: 50,
      },
      head: {
        fontSize: 14,
        fontWeight: '500',
        color: theme.palette.primary.black,
        background: '#FFF',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        height: 34,
        '&.Mui-disabled': {
          color: theme.palette.grey[700],
        },
      },
      contained: {
        backgroundColor: theme.palette.primary.blue,
        border: '1px solid transparent',
        '&:hover': {
          backgroundColor: '#3C66F8',
        },
        '&:focus': {
          border: `1px solid ${theme.palette.secondary.lightBlue}`,
        },
        '&.Mui-disabled': {
          backgroundColor: theme.palette.secondary.grey,
        },
      },
      outlined: {
        color: theme.palette.primary.black,
        border: `1px solid ${theme.palette.grey[400]}`,
        '&:hover': {
          borderColor: theme.palette.grey[500],
        },
        '&.Mui-disabled': {
          backgroundColor: '#FFFFFF',
        },
      },
      text: {
        color: theme.palette.primary.black,
        '&hover': {
          color: theme.palette.primary.blue,
        },
        '&focus': {
          border: `1px solid ${theme.palette.primary.blue}`,
          color: theme.palette.primary.blue,
        },
      },
      textSecondary: {
        color: theme.palette.primary.blue,
      },
      sizeSmall: {
        fontSize: '12px !important',
        height: '26px !important',
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: 2,
        color: theme.palette.secondary.blue,
        fontSize: 14,
        lineHeight: 1.1,
        padding: theme.spacing(0.5, 0.75),
        height: 'auto',
      },
      label: {
        padding: 0,
        display: 'block',
        whiteSpace: 'normal',
      },
      filled: {
        backgroundColor: theme.palette.secondary.grey,
        border: '1px solid transparent',
      },
      outlined: {
        backgroundColor: theme.palette.primary.light,
        border: `1px solid ${theme.palette.primary.blue}`,
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: 27,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: theme.palette.grey[400],
      },
    },
  },
};

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFF',
    },
    secondary: {
      main: '#FFF',
    },
    error: {
      main: '#e84545',
    },
  },
  typography: {
    fontFamily: 'McKinseySans, sans-serif',
  },
});
