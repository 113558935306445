import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { isEqual } from 'lodash';
import { SelectOption, WarmingLevelOption } from '@models';
import { useSettings } from './settings';
import useGetToken from '@hooks/useGetToken';
import { getModel, getScenarios, patchSelectedScenarios } from '@api';
import { SelectedScenariosLimit } from '@constants';
import FullPageLoading from '@components/FullPageLoading';

type ScenarioMapping = {
  [key: string]: {
    name: string;
    description: string | null;
    sortOrder: number;
    warming_level: WarmingLevelOption[];
    warning?: string | null;
  };
};

type ContextType = {
  scenarioOptions: SelectOption[];
  selectedScenarios: string[];
  scenarioMapping: ScenarioMapping;
  handleScenarioChange: (values: string[]) => void;
  loadingScenarios: boolean;
  errorLoadingScenarios: string;
  scenariosChanging: boolean;
};

const ScenarioSelectContext = createContext<ContextType>({
  scenarioOptions: [],
  selectedScenarios: [],
  scenarioMapping: {},
  handleScenarioChange: () => undefined,
  loadingScenarios: false,
  errorLoadingScenarios: '',
  scenariosChanging: false,
});

type ProviderProps = {
  children: JSX.Element;
};

export const ScenarioSelectProvider = ({ children }: ProviderProps) => {
  const { getToken } = useGetToken();
  const {
    settings: { scenarios, model_version },
    setSettings,
  } = useSettings();
  const [scenarioOptions, setScenarioOptions] = useState<SelectOption[]>([]);
  const [selected, setSelected] = useState<string[]>(scenarios.map((id) => `${id}`));
  const [loading, setLoading] = useState<boolean>(false);
  const [initialRendered, setInitialRendered] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [scenarioMapping, setScenarioMapping] = useState<ScenarioMapping>({});
  const [scenariosChanging, setScenariosChanging] = useState<boolean>(false);

  const handleScenarioChange = useCallback(
    async (values: string[]) => {
      if (selected.length <= SelectedScenariosLimit && !scenariosChanging) {
        const token = await getToken();
        setScenariosChanging(true);
        patchSelectedScenarios(
          token,
          values.map((id) => +id),
        )
          .then((data) => setSettings(data))
          .finally(() => setScenariosChanging(false));
      }
    },
    [getToken, setSettings, selected.length, scenariosChanging],
  );

  useEffect(() => {
    (async () => {
      const token = await getToken();
      setLoading(true);
      getScenarios(token, { model_version })
        .then((res) => {
          const sortedScenarios = [...res].sort((a, b) => a.sort_order - b.sort_order);
          setInitialRendered(true);
          setScenarioOptions(
            sortedScenarios.map((sc) => ({
              name: sc.name,
              value: `${sc.id}`,
              category: sc.category,
              warning: sc.warning,
            })),
          );
          setScenarioMapping(
            res.reduce((acc: ScenarioMapping, sc) => {
              acc[`${sc.id}`] = {
                name: sc.name,
                description: sc.description,
                sortOrder: sc.sort_order,
                warming_level: sc.warming_level.sort((a, b) => a.year - b.year),
              };
              return acc;
            }, {}),
          );
        })
        .catch((err) => setError(err?.data?.error_message))
        .finally(() => setLoading(false));
    })();
  }, [getToken, model_version]);

  useEffect(() => {
    (async () => {
      const token = await getToken();
      if (!scenarios.length) {
        const { default_scenarios } = await getModel(token, model_version);
        patchSelectedScenarios(token, default_scenarios);
      } else if (
        !isEqual(
          scenarios,
          selected.map((id) => +id),
        )
      ) {
        setSelected(scenarios.map((id) => `${id}`));
      }
    })();
  }, [getToken, scenarios, model_version, selected]);

  return (
    <ScenarioSelectContext.Provider
      value={{
        scenarioOptions,
        selectedScenarios: selected,
        scenarioMapping,
        handleScenarioChange,
        loadingScenarios: loading,
        errorLoadingScenarios: error,
        scenariosChanging,
      }}
    >
      {loading && !initialRendered ? <FullPageLoading /> : children}
    </ScenarioSelectContext.Provider>
  );
};

export const useScenarioSelect = () => useContext(ScenarioSelectContext);
